import { Routes } from '@angular/router';
import { authRoutes } from './features/auth/auth-routes';
import { complianceRoutes } from './features/compliance/compliance-routes';
import { AppLayoutComponent } from './layouts/app-layout/app-layout.component';
import { MyDashboardComponent } from './features/dashboard/components/my-dashboard/my-dashboard.component';
import { AuthGuard } from './core/guards/auth.guard';
import { settingsRoutes } from './features/settings/settings-route';
import { organizationRoutes } from './features/organization/organization-routes';
import { LoginGuard } from './core/guards/login.guard';
import { riskRoutes } from './features/risk/risk-routes';
import { reviewMeetingRoutes } from './features/review-meeting/review-meeting.route';
import { incidentRoutes } from './features/incident/incident-route';
import { documentRoutes } from './features/document/document-route';
import { auditRoutes } from './features/audit/audit.routes';
import { CommonComponentsComponent } from './shared/components/common/common-components/common-components.component';
import { dashboardRoutes } from './features/dashboard/dashboard-routes';
import { NotificationListComponent } from './core/components/notification/notification-list/notification-list.component';
import { auditInsightRoutes } from './features/audit-insight/audit-insight.routes';
import { businessContinuityRoutes } from './features/business-continuity/business-continuity.routes';
import { myProfileRoutes } from './features/my-profile/my-profile-routes';
import { CommonComponent2Component } from './shared/components/common/common-component2/common-component2.component';

export const routes: Routes = [
  {
    path: 'auth',
    children: [...authRoutes],
    canActivate: [LoginGuard],
  },
  {
    path: '',
    component: AppLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'dashboards',
        children: [...dashboardRoutes],
      },
      {
        path: 'dashboard/my-dashboard',
        component: MyDashboardComponent,
      },
      {
        path: 'common',
        component: CommonComponentsComponent,
      },
      {
        path: 'common2',
        component: CommonComponent2Component,
      },
      {
        path: 'my-profile',
        children: [...myProfileRoutes],
      },
      {
        path: 'organization',
        children: [...organizationRoutes],
      },
      {
        path: 'compliance',
        children: [...complianceRoutes],
      },
      {
        path: 'document',
        children: [...documentRoutes],
      },
      {
        path: 'incident',
        children: [...incidentRoutes],
      },
      {
        path: 'risk',
        children: [...riskRoutes],
      },
      {
        path: 'audit',
        children: [...auditRoutes],
      },
      {
        path: 'review-meeting',
        children: [...reviewMeetingRoutes],
      },
      {
        path: 'settings',
        children: [...settingsRoutes],
      },
      {
        path: 'audit-insight',
        children: [...auditInsightRoutes],
      },
      {
        path: 'business-continuity',
        children: [...businessContinuityRoutes],
      },
      {
        path: 'notifications/db-notifications',
        component: NotificationListComponent,
      },
    ],
  },
  {
    path: '',
    redirectTo: 'dashboard/my-dashboard',
    pathMatch: 'full',
  },
];
