import { Injectable } from '@angular/core';
import { UserConversion } from '../../features/organization/users/conversion/user.conversion';
import { MainConversionInterface } from '../interfaces/conversion/main-conversion.interface';
import { NotificationListDTO } from '../dto/notification.dto';
import { FormGroup } from '@angular/forms';
import { CurrentUserPreferenceStore } from '../store/current-user-preference.store';
import { getParamsFromUrlParam } from '../modals/params.modal';

@Injectable({
  providedIn: 'root',
})
export class NotificationConversion
  implements MainConversionInterface<NotificationListDTO, undefined, undefined>
{
  private lang: string;
  constructor(
    private currentUserPreferenceStore: CurrentUserPreferenceStore,
    private userConversion: UserConversion
  ) {
    this.lang = currentUserPreferenceStore.languageKey;
  }

  resToList(response: any): NotificationListDTO[] {
    let notifications: NotificationListDTO[] = [];

    response.map((item: any) => {
      // Prepare the URL using the prepareURL function
      const url = this.prepareURL(item.notification_type, item.url_param);
      const action = this.prepareAction(item.action);
      const entity = this.prepareEntity(item.entity);
      const icon = this.prepareIcon(item.notification_type);

      let notification: NotificationListDTO = {
        id: item.id,
        notification_type: item.notification_type,
        module: {
          identifier: item.module_identifier,
          title: item['module_title_' + this.lang],
        },
        heading: item['heading_' + this.lang],
        reference_number: item.reference_number,
        title: item['title_' + this.lang],
        url: url,
        entity: entity,
        icon: icon,
        action: action,
        is_new: item.is_new,
        is_read: item.is_read,
        is_system_notification: item.is_system_notification,
        created_by: this.userConversion.resToUserMinimalDTO(item, 'created_by'),
        created_at: item.created_at,
      };

      notifications.push(notification);
    });

    return notifications;
  }

  formGroupToForm(formGroup: FormGroup): undefined {
    throw new Error('Method not implemented.');
  }
  resToForm(response: any) {
    throw new Error('Method not implemented.');
  }
  resToDetails(response: any): undefined {
    throw new Error('Method not implemented.');
  }

  prepareURL(type: string, url_param: any): string {
    const params = getParamsFromUrlParam(url_param);
    let url = '';
    if(params){
      if (type === 'assignment') {
        url = `organization/process/${params.id}/overview`;
      }
    }
    
    return url;
  }

  prepareAction(action: string) : string {
    switch(action){
      case 'responsible-user-assign':
        return 'You have been assigned as the responsible user';
      case 'responsible-user-remove':
        return 'removed as the responsible user';
      case 'send-commencement-letter':
        return 'You have received the commencement letter';
      default:
        return 'You have received the commencement letter';
    }
  }

  prepareEntity(entity: string) : string {
    switch(entity){
      case 'control':
        return 'control';
      case 'process':
        return 'process';
      case 'audit-plan':
        return 'audit plan';
      case 'incident-register':
        return 'incident register';
      case 'compliance-register':
        return 'compliance register';
      case 'risk-action-plan':
        return 'risk action plan';
     
      default:
        return 'test';
    }
  }

  prepareIcon(notificationType: string): string {
    switch(notificationType) {
      case 'assignment':
        return 'icon-users.svg';
      case 'security':
         return 'icon-users.svg';
      case 'action':
        return 'icon-users.svg';
      case 'deletion':
        return 'icon-users.svg';
      case 'completion':
        return 'icon-users.svg';
      case 'reminder':
        return 'icon-users.svg';
      case 'warning':
        return 'icon-users.svg';
      case 'information':
        return 'icon-users.svg';
      case 'acknowledgment':
        return 'icon-users.svg';
      case 'escalation':
        return 'icon-users.svg';
      case 'deadline':
        return 'icon-users.svg';
      case 'approval':
        return 'icon-users.svg';
      case 'review':
        return 'icon-users.svg';
      case 'reject':
        return 'icon-users.svg';
      case 'revert':
        return 'icon-users.svg';
      case 'comment':
        return 'icon-users.svg';
      case 'publish':
        return 'icon-users.svg';
      case 'confirmation':
        return 'icon-users.svg';
      case 'error':
        return 'icon-users.svg';
     
      default:
         return 'icon-users.svg';
    }
  }

  
}
