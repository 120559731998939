<li class="d-flex"
    style="cursor: pointer;"
    [class.unread]="!notificationItem.is_read"
    (click)="navigateToUrl(notificationItem)">
    <div class="notification-icon me-3">
        <img  src="assets/images/notification-icons/{{ notificationItem.icon }}" />
       
    </div>
    <div class="notification-description">
        <p>
        <strong>{{ notificationItem.heading }}:</strong>
        {{ notificationItem.action }} for the {{ notificationItem.entity }} titled
            <strong>
                @if(notificationItem.reference_number){
                    <span>
                        {{ notificationItem.reference_number }} -
                    </span>
                }
                {{ notificationItem.title }}
            </strong>
        </p>

    <div class="notification-time d-flex">
        <img src="assets/images/notification-icons/icon-clock.svg" />
        {{ notificationItem.created_at | dateTimeFormat }}
    </div>
    </div>
</li>