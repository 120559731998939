import { Component } from '@angular/core';

@Component({
  selector: 'app-common-component2',
  standalone: true,
  imports: [],
  templateUrl: './common-component2.component.html',
  styleUrl: './common-component2.component.scss'
})
export class CommonComponent2Component {

}
